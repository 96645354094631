import { type FC } from 'react'

import { type Identifier } from 'appTypes'
import Icons from 'assets/icons'
import { InfoCard, InfoCardDetails, InfoCardHeader, formatMoney } from 'components'
import { type Action, deleteOneAction } from 'core'
import { formatDate } from 'lib'
import { createdField } from 'resources/base'
import { displayPaymentMethod } from 'resources/paymentMethods'
import { PurchaseInvoicesField } from 'resources/purchaseInvoices'

import { deletePaymentAction } from '../actions'
import { type PaymentModel } from '../types'

import PaymentCardTitle from './PaymentCardTitle'
import PaymentDrawerToggler from './PaymentDrawerToggler'

interface Props {
    payment: PaymentModel
    index: number
    readOnly?: boolean
    refund?: boolean
    invoicesUrl?: string
}

const PaymentCard: FC<Props> = ({ refund, payment, index, readOnly, invoicesUrl }) => {
    return (
        <InfoCard>
            <InfoCardHeader
                actions={(_, args) =>
                    paymentActions(
                        { id: payment.id, disabled: readOnly, invoicesUrl, refund },
                        args,
                    )
                }
                title={
                    <PaymentCardTitle
                        payment={payment}
                        index={index}
                        readOnly={readOnly}
                        invoicesUrl={invoicesUrl}
                        refund={refund}
                    />
                }
            />
            <InfoCardDetails<PaymentModel>
                details={[
                    {
                        label: refund ? 'amount' : 'amount paid',
                        source: ({ amount }) =>
                            formatMoney({
                                value: refund ? -Math.abs(amount) : amount,
                                negativeFormat: 'accounting',
                                featured: true,
                            }),
                    },
                    {
                        label: 'added on',
                        source: (record) => createdField.value(record.created),
                    },
                    {
                        label: refund ? 'date' : 'payment date',
                        source: (record) =>
                            formatDate(record.date, (dateFormats) => dateFormats.shortenedDate),
                    },
                    invoicesUrl
                        ? {
                              label: 'reference (INV No)',
                              valueFullWidth: true,
                              source: (record) => (
                                  <PurchaseInvoicesField
                                      justify="flex-end"
                                      data={record.invoicesData}
                                      chipSize="extraSmall"
                                  />
                              ),
                          }
                        : undefined,
                    {
                        label: 'payment method',
                        source: (record) => displayPaymentMethod(record.method),
                    },
                    {
                        label: refund ? 'Check/Refund number' : 'Payment/Check number',
                        source: 'number',
                    },
                    {
                        label: 'Notes',
                        source: 'notes',
                    },
                ]}
                propRecord={payment}
            />
        </InfoCard>
    )
}

export default PaymentCard

interface PaymentActionsProps {
    id: Identifier
    disabled: boolean
    invoicesUrl?: string
    refund?: boolean
}

const paymentActions: Action<PaymentActionsProps> = (
    { id, disabled, invoicesUrl, refund },
    { children },
) => [
    <PaymentDrawerToggler
        key="edit"
        id={id}
        children={(open) => children({ title: 'View/Edit', Icon: Icons.Edit, onClick: open })}
        readOnly={disabled}
        invoicesUrl={invoicesUrl}
        refund={refund}
    />,
    deleteOneAction({
        children,
        disabled,
        id,
        ...deletePaymentAction(refund),
    }),
]
