import { type FC } from 'react'

import { useRecordContext } from 'react-admin'

import { type WithChildrenElement } from 'appTypes'
import { useClearResource, RaResourceContextProvider } from 'core'
import { getMetersResource } from 'resources/units'

const UnitMetersResource: FC<WithChildrenElement> = ({ children }) => {
    const { id } = useRecordContext() || {}

    const metersResource = getMetersResource(id)

    useClearResource(metersResource.resource)

    if (!id) {
        return null
    }

    return <RaResourceContextProvider value={metersResource}>{children}</RaResourceContextProvider>
}

export default UnitMetersResource
