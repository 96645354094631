import { type FC, type ReactElement } from 'react'

import { inject, observer } from 'mobx-react'
import { useRecordContext } from 'react-admin'

import { type Identifier } from 'appTypes'
import {
    type UtilityDrawerEditorProps,
    useOpenUtilityDrawer,
    UtilityDrawerEditor,
} from 'components'
import { authStore, type AuthStore, NotificationMessage, serialize, type Serializer } from 'core'
import { useCreateResourcePath } from 'core/resource'

import { type UnitModel } from '../types'
import unitResource from '../unitResource'
import { equipmentHasBodySource } from '../utils'

import UnitForm from './UnitForm'

interface UnitDrawerTogglerProps extends Pick<UtilityDrawerEditorProps, 'onSuccess'> {
    children: (params: { onClick: () => void }) => ReactElement
    id?: Identifier
    defaultValues?: Partial<UnitModel>
    shortSuccessMessage?: boolean
}

const UnitDrawerToggler: FC<UnitDrawerTogglerProps> = inject('auth')(
    observer(
        ({
            children,
            id,
            onSuccess,
            defaultValues,
            shortSuccessMessage,
            auth,
        }: UnitDrawerTogglerProps & { auth: AuthStore }) => {
            const open = useOpenUtilityDrawer()
            const createPath = useCreateResourcePath()

            return children({
                onClick: () => {
                    open({
                        extraArgs: {
                            resource: unitResource,
                            id,
                        },
                        drawerArgs: {
                            title: id ? 'Edit Details' : 'Create Unit',
                            renderWrapper: (props) => (
                                <UtilityDrawerEditor
                                    defaultValues={defaultValues}
                                    onSuccess={onSuccess}
                                    {...props}
                                    serializer={unitSerializer}
                                    successMessage={({ defaultMessages, response }) => {
                                        if (id) {
                                            return defaultMessages.updated
                                        }
                                        if (shortSuccessMessage) {
                                            return defaultMessages.created
                                        }

                                        const unit = response as UnitModel

                                        return {
                                            title: defaultMessages.created,
                                            message: (
                                                <NotificationMessage.Navigate
                                                    to={createPath({
                                                        resource: unitResource.resource,
                                                        type: 'edit',
                                                        id: unit.id,
                                                    })}
                                                >
                                                    {`Unit ${unit.number}`}
                                                </NotificationMessage.Navigate>
                                            ),
                                        }
                                    }}
                                />
                            ),
                            renderContent: () =>
                                id ? (
                                    <UnitEditForm />
                                ) : (
                                    <UnitForm
                                        inputsProps={{
                                            domicile: {
                                                defaultValue:
                                                    !auth.companySettings.viewUnitsWithoutDomicile,
                                            },
                                        }}
                                    />
                                ),
                        },
                    })
                },
            })
        },
    ),
)

const UnitEditForm: FC = () => {
    const record = useRecordContext<UnitModel>() || ({} as UnitModel)

    return (
        <UnitForm
            inputsProps={{
                domicile: {
                    disabled: record.hasOpenWorkOrders,
                    defaultValue: false,
                },
                customerUnit: {
                    hide: true,
                },
                customer: {
                    disabled: true,
                    hide: !record?.customer,
                },
                costCenter: record.hasOpenWorkOrders
                    ? {
                          disabled: true,
                          helperText: 'Editing is locked due to open Work Orders',
                      }
                    : null,
                division: record.hasOpenWorkOrders
                    ? {
                          disabled: true,
                          helperText: 'Editing is locked due to open Work Orders',
                      }
                    : null,
            }}
        />
    )
}

export default UnitDrawerToggler

const unitSerializer: Serializer<UnitModel> = [
    { name: 'customer', parse: ['emptyToNull', 'disableOnEdit'] },
    'number',
    'name',
    'vin',
    'serialNumber',
    'licensePlate',
    'model',
    'domicile',
    { name: 'modelYear', parse: 'year' },
    'engineModel',
    { name: 'engineHp', parse: 'number' },
    { name: 'engineVmrsManufacturer', parse: 'emptyToNull' },
    'transmissionModel',
    { name: 'transmissionGears', parse: 'number' },
    'color',
    'tireSize',
    {
        name: 'status',
        parse: (value, data) => {
            if (!authStore.companySettings.hasUnitStatuses || data.id) {
                return undefined
            }

            return value || null
        },
    },
    { name: 'photo', parse: 'file' },
    { name: 'vmrsEquipmentCategory', parse: 'emptyToNull' },
    { name: 'vmrsManufacturer', parse: 'emptyToNull' },
    { name: 'transmissionVmrsManufacturer', parse: 'emptyToNull' },
    { name: 'tags', parse: 'emptyToNull' },
    {
        name: 'body',
        parse: (values, data) => {
            if (!data[equipmentHasBodySource]) {
                return undefined
            }

            return serialize(values, [
                { name: 'vmrsType', parse: 'emptyToNull' },
                { name: 'vmrsMaterial', parse: 'emptyToNull' },
                { name: 'vmrsManufacturer', parse: 'emptyToNull' },
                'serialNumber',
                'model',
                { name: 'modelYear', parse: 'year' },
            ])
        },
    },
    {
        name: 'costCenter',
        parse: ['emptyToNull', (value, data) => (data.hasOpenWorkOrders ? undefined : value)],
    },
    {
        name: 'division',
        parse: ['emptyToNull', (value, data) => (data.hasOpenWorkOrders ? undefined : value)],
    },
]
