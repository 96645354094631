import { type FC } from 'react'

import {
    DateInput,
    TextInput,
    costNonNegativeMaskParams,
    inputPaymentCheckMaskParams,
    TextareaInput,
    AutocompleteInput,
} from 'components'
import { maxLengthValidationText, ReferenceArrayInput, requiredValidation } from 'core'
import { type InvoiceModel } from 'resources/invoices'
import { PaymentMethodInput } from 'resources/paymentMethods'

import { type PaymentKeys } from '../types'

interface Props {
    refund?: boolean
    disabledFields?: (source: PaymentKeys) => boolean
    invoicesUrl?: string
}

const PaymentForm: FC<Props> = ({ refund, invoicesUrl, disabledFields }) => {
    return (
        <>
            <TextInput
                source={'amount' satisfies PaymentKeys}
                label="Amount Paid"
                disabled={disabledFields?.('amount')}
                validate={requiredValidation}
                {...costNonNegativeMaskParams}
            />

            <DateInput
                source={'date' satisfies PaymentKeys}
                label={refund ? 'Date' : 'Payment Date'}
                disabled={disabledFields?.('date')}
                defaultValue={new Date()}
                validate={requiredValidation}
            />

            {invoicesUrl ? (
                <ReferenceArrayInput
                    source="invoices"
                    reference={invoicesUrl}
                    disabled={disabledFields?.('invoices')}
                    perPage={100}
                >
                    <AutocompleteInput
                        multiple
                        label="Reference (INV No)"
                        disabled={disabledFields?.('invoices')}
                        optionText={renderInvoice}
                        filterSelectedOptions
                    />
                </ReferenceArrayInput>
            ) : null}

            <PaymentMethodInput
                source={'method' satisfies PaymentKeys}
                disabled={disabledFields?.('method')}
            />

            <TextInput
                source={'number' satisfies PaymentKeys}
                disabled={disabledFields?.('number')}
                label={refund ? 'Check/Refund Number' : 'Payment/Check Number'}
                {...inputPaymentCheckMaskParams}
            />

            <TextareaInput
                source={'notes' satisfies PaymentKeys}
                label="Notes"
                disabled={disabledFields?.('notes')}
                validate={maxLengthValidationText}
            />
        </>
    )
}

export default PaymentForm

const renderInvoice = (invoice: InvoiceModel) => invoice.number
