import { useRecordContext } from 'react-admin'

import { useDistanceLabel, formatMoney } from 'components'
import { SliderWidget, SliderWidgetSkeleton } from 'pages/Dashboard/components/WidgetBase'
import { WidgetLineChartBase } from 'pages/Dashboard/components/Widgets/components'
import { type UnitModel } from 'resources/units'
import { Box } from 'ui'

import { Clarification } from './components'

const description = `The accumulated maintenance expenses
 are divided by the total mileage logged, empowering you to spot the trend at once.`
let isValid = false

const UnitCostPerDistanceWidget = () => {
    const record = useRecordContext<UnitModel>()
    const distanceLabel = useDistanceLabel({ textCase: 'upper', variant: 'short-abbr' })

    if (!record) {
        return <SliderWidgetSkeleton />
    }
    isValid = typeof record.costPerDistance === 'number'

    return (
        <SliderWidget
            headerProps={{
                title: `Maintenance CP${distanceLabel}`,
                label: formatMoney(record.costPerDistance),
                tooltipText: description,
                clarification: <Clarification />,
                chart: (
                    <Box mt="10px">
                        <WidgetLineChartBase
                            data={record.costPerDistancePoints ?? []}
                            isValid={isValid}
                            height={95}
                            width={89}
                            margin={{ top: 10, right: 4, bottom: 30, left: 4 }}
                        />
                    </Box>
                ),
            }}
        />
    )
}
export default UnitCostPerDistanceWidget
