import { type FC } from 'react'

import { inject, observer } from 'mobx-react'
import { type Validator } from 'react-admin'

import images from 'assets/images'
import { SaveFixedOnMobile, Form, CombinedInputs, PasswordInput } from 'components'
import ThemeButton from 'components/buttons/ThemeButton'
import { config } from 'configs'
import { useSubmit, passwordValidation, requiredValidation, validatePasswordMatch } from 'core'
import { useAuthProvider, type AuthStore } from 'core/auth'
import { useFlags } from 'lib'
import {
    GridItemLayout,
    SectionTitle,
    Img,
    GridContainerColumns,
    PageContent,
    Typography,
    BoxContainer,
} from 'ui'

interface FormValues {
    currentPassword: string
    newPassword: string
}

const passwordMatchValidation: Validator[] = [
    requiredValidation,
    validatePasswordMatch('newPassword'),
]

const AccountSettings = inject('auth')(
    observer(({ auth }: { auth: AuthStore }) => {
        const user = auth.user
        const authProvider = useAuthProvider()
        const { flagDarkMode, showVersionInMobileMode } = useFlags()

        const submitHandler = useSubmit<FormValues>(
            async ({ currentPassword, newPassword }) => {
                await authProvider.changePassword(currentPassword, newPassword)
                await auth.fetchUser()
            },
            {
                successMessage: ({ defaultMessages }) => defaultMessages.updated,
            },
        )

        return (
            <PageContent>
                <SectionTitle>
                    {user.hasUsablePassword ? 'Change Password' : 'Set Password'}
                </SectionTitle>
                <Form
                    resetOnSuccess
                    onSubmit={submitHandler}
                    formRootPathname="account-settings"
                >
                    <GridContainerColumns>
                        <GridItemLayout>
                            {user.hasUsablePassword ? (
                                <PasswordInput
                                    source="currentPassword"
                                    label="Your Current Password"
                                    autoComplete="new-password"
                                    validate={requiredValidation}
                                />
                            ) : (
                                <BoxContainer mb="32px">
                                    <Img
                                        src={images.google}
                                        alt="google"
                                        width="24px"
                                        sx={{ mr: '10px' }}
                                    />
                                    <Typography variant="body1">Registered with Google</Typography>
                                </BoxContainer>
                            )}

                            <PasswordInput
                                source="newPassword"
                                label="New Password"
                                validate={passwordValidation}
                                helperText="Use at least 8 characters"
                            />
                            <CombinedInputs
                                sources={['confirmPassword']}
                                track={['newPassword']}
                            />
                            <PasswordInput
                                source="confirmPassword"
                                validate={passwordMatchValidation}
                                label="Confirm New Password"
                            />
                        </GridItemLayout>
                    </GridContainerColumns>
                    <SaveFixedOnMobile />
                </Form>
                {flagDarkMode && (
                    <BoxContainer>
                        <ThemeButton mode="light" />
                        <ThemeButton mode="system" />
                        <ThemeButton mode="dark" />
                    </BoxContainer>
                )}
                {showVersionInMobileMode ? config.VERSION : null}
            </PageContent>
        )
    }),
) as FC

export default AccountSettings
