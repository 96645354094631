import { useListContext, useRecordContext, useShowContext } from 'react-admin'

import { ListController, ListUi } from 'components'
import { perPageLimit } from 'core'
import { Navigate, useParams } from 'lib'
import { type ReceiptModel, type PurchaseOrderModel } from 'resources/purchaseOrders'
import { Stack, FlexReverse, PageContent } from 'ui'

import ReturnItems from '../ReturnItems'

import { type ParamsType, LineItemsList, ReceiptSection, ReceiptToggle } from './components'
import ReceiptsList from './components/ReceiptsList'

const LineItems = () => {
    const record = useRecordContext<PurchaseOrderModel>()

    if (!record) {
        return (
            <PageContent>
                <ListController
                    sort={{ field: 'id', order: 'ASC' }}
                    isLoading
                >
                    <ListUi />
                </ListController>
            </PageContent>
        )
    }

    if (record.type === 'CREDIT') {
        return <ReturnItems />
    }

    return (
        <ReceiptsList
            perPage={perPageLimit}
            disableSyncWithLocation
        >
            <POLineItems />
        </ReceiptsList>
    )
}

const POLineItems = () => {
    const { receipt } = useParams<ParamsType>()

    const { record, isLoading: isShowLoading } = useShowContext<PurchaseOrderModel>()
    const { data, isFetching } = useListContext()

    const receiptId = !receipt && record?.status === 'CLOSED' && data ? data[0]?.id : receipt
    const receiptData: ReceiptModel = data?.find(({ id }) => id === receiptId)

    if (!receiptData && isFetching === false && receiptId && !isShowLoading) {
        return <Navigate to=".." />
    }

    return (
        <FlexReverse flex={1}>
            <Stack flex={1}>
                {record?.dateLastReceived && <ReceiptToggle />}
                {receiptData && <ReceiptSection receiptData={receiptData} />}
                <LineItemsList receipt={receiptData} />
            </Stack>
        </FlexReverse>
    )
}

export default LineItems
