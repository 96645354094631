import { type FC } from 'react'

import { useRecordContext } from 'react-admin'

import { type Identifier } from 'appTypes'
import images from 'assets/images'
import {
    FilterDateRangeValue,
    FilterValueInput,
    ListController,
    type ListControllerProps,
    ListUi,
    type CardListConfig,
    type DatagridColumnsProps,
    formatMoney,
} from 'components'
import { type ResourceType, ResourceContextProviderWithClearEffect } from 'core'
import { formatDate, globalClassNames } from 'lib'
import {
    type PartInInventoryModel,
    type InventoryHistoryModel,
    inventoryItemsResource,
} from 'resources/inventory'
import { getReasonForQTYAdjustmentLabel } from 'resources/reasonForQtyAdjustment'
import { Box, Tooltip, Chip, PageContent, BoxContainer } from 'ui'
import { capitalizeWithLowerCase } from 'utils'

import { InventoryHistoryHeader } from './components'

const ChangeChip = ({ label }: { label: string }) => {
    return (
        <Chip
            component="span"
            sx={{
                overflow: 'hidden',
            }}
            label={label}
            variant="filled"
            size="extraSmall"
        />
    )
}

const ChangeField = ({ record }: { record: InventoryHistoryModel }) => {
    return (
        <BoxContainer
            gap="8px"
            overflow="hidden"
        >
            {record.quantityAdjustment ? <ChangeChip label="Quantity" /> : null}
            {record.costAdjustment ? <ChangeChip label="Cost" /> : null}
        </BoxContainer>
    )
}

const getEventType = (type: InventoryHistoryModel['type']): string => {
    return eventTypeMap[type]
}

const filters: ListControllerProps<InventoryHistoryModel & { change: string }>['filters'] = [
    {
        label: 'Event Types',
        id: 'type',
        renderComponent: (props) => (
            <FilterValueInput
                {...props}
                makeItemLabel={(record) => {
                    return getEventType(record.id as InventoryHistoryModel['type'])
                }}
            />
        ),
    },
    {
        label: 'Change',
        id: 'change',
        renderComponent: (props) => (
            <FilterValueInput
                {...props}
                makeItemLabel={(record) => {
                    return capitalizeWithLowerCase(record.id as string)
                }}
            />
        ),
    },
    {
        label: 'Date',
        id: 'created',
        renderComponent: (props) => <FilterDateRangeValue {...props} />,
    },

    {
        label: 'Reason For QTY Adjustment',
        id: 'quantityAdjustmentReason',
        renderComponent: (props) => (
            <FilterValueInput
                {...props}
                makeItemLabel={(record) => {
                    return getReasonForQTYAdjustmentLabel(record.id as string)
                }}
            />
        ),
    },

    {
        label: 'Responsible User',
        id: 'createdBy',
    },
]

const sorts: ListControllerProps<InventoryHistoryModel>['sorts'] = [
    { label: 'Event Types', id: 'type' },
    {
        label: 'Date',
        id: 'created',
    },
    { label: 'QTY Before Change', id: 'quantityBefore' },
    { label: 'QTY After Change', id: 'quantityAfter' },
    { label: 'Cost Before Change', id: 'costBefore' },
    { label: 'Cost After Change', id: 'costAfter' },
]

const eventTypeMap: {
    [key in InventoryHistoryModel['type']]: string
} = {
    PURCHASE_ORDER: 'Purchase Order',
    MANUAL: 'Manual Adjust',
    WORK_ORDER: 'Work Order',
    INVOICE: 'Invoice',
}

const getCreatedBy = (userData: InventoryHistoryModel['createdBy']) => {
    if (!userData) {
        return null
    }
    return [userData.name, userData.email].filter(Boolean).join(' • ')
}

const columnsCfg: DatagridColumnsProps<InventoryHistoryModel & { change: string }> = {
    checkboxSelection: false,
    actions: null,
    constantColumns: {
        change: true,
        created: true,
    },
    mainField: 'type',
    pinnedColumns: {
        left: ['created', 'change'],
    },
    columns: [
        {
            headerName: 'Event Types',
            field: 'type',
            valueFormatter: ({ value }) => getEventType(value),
        },
        {
            headerName: 'Date',
            field: 'created',
            valueFormatter: ({ value }) =>
                formatDate(value, (dateFormats) => dateFormats.shortenedDateTime),
        },
        {
            headerName: 'Change',
            field: 'change',
            renderCell: ({ row }) => <ChangeField record={row} />,
        },
        { headerName: 'QTY Before Change', field: 'quantityBefore' },
        { headerName: 'QTY After Change', field: 'quantityAfter' },
        {
            headerName: 'Reason For QTY Adjustment',
            field: 'quantityAdjustmentReason',
            valueFormatter: ({ value }) => getReasonForQTYAdjustmentLabel(value),
        },
        {
            headerName: 'Cost Before Change',
            field: 'costBefore',
            align: 'right',
            renderCell: ({ value }) => formatMoney(value),
        },
        {
            headerName: 'Cost After Change',
            field: 'costAfter',
            align: 'right',
            renderCell: ({ value }) => formatMoney(value),
        },
        {
            headerName: 'Responsible User',
            field: 'createdBy',
            renderCell: ({ value }) => getCreatedBy(value),
        },
        {
            headerName: 'Comment',
            field: 'comment',
            renderCell: ({ value }) =>
                value ? (
                    <Tooltip title={value}>
                        <Box className={globalClassNames.ellipsis}>{value}</Box>
                    </Tooltip>
                ) : (
                    value
                ),
        },
    ],
}

const cardsCfg: CardListConfig<InventoryHistoryModel & { change: string }> = {
    titleSource: (record) => getEventType(record.type),
    disableTitleLink: true,
    defaultImage: undefined,
    details: [
        {
            label: 'Date',
            source: 'created',
            render: (value) => formatDate(value, (dateFormats) => dateFormats.shortenedDateTime),
        },
        {
            label: 'Change',
            source: 'change',
            render: (v, record) => <ChangeField record={record} />,
        },
        { label: 'QTY Before Change', source: 'quantityBefore' },
        { label: 'QTY After Change', source: 'quantityAfter' },
        {
            label: 'Reason For QTY Adjustment',
            source: 'quantityAdjustmentReason',
            render: (value) => getReasonForQTYAdjustmentLabel(value),
        },
        {
            label: 'Cost Before Change',
            source: 'costBefore',
            render: (value) => formatMoney(value),
        },
        {
            label: 'Cost After Change',
            source: 'costAfter',
            render: (value) => formatMoney(value),
        },
        {
            label: 'Responsible User',
            source: 'createdBy',
            render: (value) => getCreatedBy(value),
        },
        { label: 'Comment', source: 'comment' },
    ],
}

const inventoryHistoryResource = (id: Identifier): ResourceType => ({
    name: 'inventory-transactions',
    resource: `${inventoryItemsResource.resource}/${id}/transactions`,
})

const InventoryHistory: FC = () => {
    const record = useRecordContext<PartInInventoryModel>()

    if (!record) {
        return null
    }

    const shopName = record.inventoryItem.shopData.name

    return (
        <PageContent>
            <ResourceContextProviderWithClearEffect
                value={inventoryHistoryResource(record.inventoryItem.id)}
            >
                <ListController
                    isLoading={!record}
                    preferencesName="transactions"
                    sorts={sorts}
                    filters={filters}
                >
                    <InventoryHistoryHeader />
                    <ListUi
                        columnsCfg={columnsCfg}
                        cardsCfg={cardsCfg}
                        exportFileName={`${record.number}-${shopName}-inventory-history`}
                        listFTUProps={{
                            title: 'No Data',
                            imageSrc: images.purchaseHistoryFtu,
                            linkText: null,
                        }}
                    />
                </ListController>
            </ResourceContextProviderWithClearEffect>
        </PageContent>
    )
}

export default InventoryHistory
