import { useRecordContext } from 'react-admin'

import { DynamicRoutes, tabRoutes } from 'components'
import { Show } from 'components/resource'
import { Routes } from 'lib'
import { type PurchaseOrderModel } from 'resources/purchaseOrders'

import { LineItems } from '../LineItems'
import { PurchaseOrderOverview } from '../Overview'
import PoPayments from '../PoPayments'

import { PoShowHeader } from './components'

const PurchaseOrderShow = () => {
    return (
        <Show>
            <POShowContent />
        </Show>
    )
}

const POShowContent = () => {
    const record = useRecordContext<PurchaseOrderModel>()
    const { tabs, routes } = tabRoutes(
        [
            {
                value: '',
                label: 'Overview',
                element: <PurchaseOrderOverview />,
            },
            record?.type === 'CREDIT' && record?.status === 'CANCELED'
                ? null
                : {
                      value: 'line-items/*',
                      label: record?.type === 'CREDIT' ? 'Return Items' : 'Line Items',
                      path: 'line-items',
                      element: (
                          <DynamicRoutes
                              path=":receipt"
                              element={<LineItems />}
                          />
                      ),
                  },
            {
                value: 'payments',
                label: record?.type === 'CREDIT' ? 'Refunds' : 'Payments',
                element: <PoPayments />,
            },
        ].filter(Boolean),
    )
    return (
        <>
            <PoShowHeader tabs={tabs} />
            <Routes>{routes}</Routes>
        </>
    )
}

export default PurchaseOrderShow
