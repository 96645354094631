import { useRecordContext } from 'react-admin'

import Icons from 'assets/icons'
import { formatMoney } from 'components'
import { useResizeObserverWidth } from 'hooks'
import { useWidgetContext } from 'pages/Dashboard/components/GridLayout'
import { SliderWidget, SliderWidgetSkeleton } from 'pages/Dashboard/components/WidgetBase'
import { checkWidgetDimensions } from 'pages/Dashboard/components/WidgetBase/useCollapseWidget'
import {
    Clarification,
    CostPerUnitChart,
} from 'pages/Dashboard/components/Widgets/CostPerUnitWidget/components'
import { type JobItemsCalculations } from 'pages/Dashboard/components/Widgets/components/types'
import { roundWidgetJobsData } from 'pages/Dashboard/components/Widgets/utils'
import { type LineItemTypesUnion } from 'resources/lineItems'
import { type WorkOrderModel } from 'resources/workOrders'
import { BoxContainer, Typography } from 'ui'

import LineItemsTooltip from '../Jobs/WorkOrderJobCard/components/LineItemsTooltip'

export interface ItemInfoType
    extends Record<LineItemTypesUnion, { color: string; total: number }> {}

export const AcrossAllItemsTooltip = ({
    tooltipData,
}: {
    tooltipData: Partial<JobItemsCalculations>
}) => {
    const context = useWidgetContext()
    return (
        <BoxContainer alignItems="center">
            <LineItemsTooltip data={tooltipData}>
                <Typography
                    component={BoxContainer}
                    color="text.secondary"
                    ml="5px"
                    variant="chartListItem"
                >
                    <BoxContainer
                        fontSize={
                            checkWidgetDimensions(context?.dimensions, ['sm', 'mdY'])
                                ? '10px'
                                : '12px'
                        }
                    >
                        <Icons.InfoOutlined sx={{ width: '10px' }} />
                        &nbsp;Across All Line Items
                    </BoxContainer>
                </Typography>
            </LineItemsTooltip>
        </BoxContainer>
    )
}

const WorkOrderFinancialInfo = () => {
    const { ref, match } = useResizeObserverWidth(360)

    const record = useRecordContext<WorkOrderModel>()
    if (!record) {
        return <SliderWidgetSkeleton />
    }
    const ratioData = roundWidgetJobsData(record)
    const validData = ratioData.total > 0
    const hasData = typeof ratioData.total === 'number'

    return (
        <SliderWidget
            ref={ref}
            headerProps={{
                title: 'Total Cost',
                label: formatMoney(ratioData.total),
                clarification: (
                    <Clarification
                        {...ratioData}
                        showTooltip={match}
                        validData={hasData}
                    />
                ),
                chart: (
                    <CostPerUnitChart
                        {...ratioData}
                        disabled={!validData}
                    />
                ),
            }}
        />
    )
}
export default WorkOrderFinancialInfo
