import { type FC, type ReactElement } from 'react'

import { useRecordContext } from 'react-admin'

import { useOpenUtilityDrawer, UtilityDrawerForm } from 'components'
import { api, serialize, type Serializer, updatedMessage, useSubmit } from 'core'
import { companyResource } from 'resources/company'
import { isUnitArchived, meterTypesForUnit, unitResource, type UnitModel } from 'resources/units'

import SettingsForm from './SettingsForm'

interface SettingsDrawerTogglerProps {
    children: (params: { onClick: () => void }) => ReactElement
}

const SettingsDrawerToggler: FC<SettingsDrawerTogglerProps> = ({ children }) => {
    const open = useOpenUtilityDrawer()
    const unit = useRecordContext<UnitModel>()

    const handleSubmit = useSubmit(
        async (formData) => {
            const serializer: Serializer = meterTypesForUnit
                .filter((meterType) => {
                    return unit.settings[meterType]?.canUntrack !== false
                })
                .map((meterType) => ({
                    name: meterType,
                    parse: 'boolean',
                }))

            await api.post(unitResource.settings(unit.id), serialize(formData, serializer))
        },
        {
            successMessage: updatedMessage,
        },
    )

    if (isUnitArchived(unit)) {
        return null
    }

    return children({
        onClick: () => {
            open({
                drawerArgs: {
                    title: 'Edit Tracked Meters',
                    renderWrapper: (params) => (
                        <UtilityDrawerForm
                            {...params}
                            onSubmit={handleSubmit}
                        />
                    ),
                    renderContent: () => <SettingsForm settings={unit.settings} />,
                    renderBottomRight: (render) => render({ formType: 'edit' }),
                },
                extraArgs: {
                    resource: companyResource,
                },
            })
        },
    })
}

export default SettingsDrawerToggler
