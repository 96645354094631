import { type FC, useState, type ReactNode } from 'react'

import Icons from 'assets/icons'
import { type ResourceType, useResource } from 'core/resource'
import { type Flags, useFlags } from 'lib'
import { Menu as MenuBase, type MenuProps, IconBox, type IconBoxProps } from 'ui'

import { type Action, type ActionChildren } from '../Actions'

import {
    ActionsMenuContextProvider,
    useActionsMenuContext,
    type ActionsMenuContextValue,
} from './ActionsMenuContext'
import ActionsOption, { type ActionsOptionProps } from './ActionsOption'

export type MenuItemActions<ActionType = any, ExtraActionsType = {}> = Action<
    ActionType,
    ExtraActionsType,
    ActionChildren<ActionsOptionProps>
>
// TODO: Action - make actionArgs required id ExtraActionsType is passed
export interface MenuActionProps<T = any, ExtraActionsType = {}>
    extends Pick<
        MenuProps,
        'anchorOrigin' | 'transformOrigin' | 'sx' | 'disablePortal' | 'PaperProps'
    > {
    actions: MenuItemActions<T, ExtraActionsType & { anchorEl?: HTMLElement; flags: Flags }>
    record?: any
    className?: string
    icon?: FC
    iconColor?: IconBoxProps['iconColor']
    iconBackgroundColor?: IconBoxProps['backgroundColor']
    resource?: ResourceType
    actionArgs?: ExtraActionsType
    disabled?: boolean
    title?: string
    renderToggler?: (open: MenuActionsRenderToggleArg, isOpen?: boolean) => ReactNode
}

export type MenuActionsRenderToggleArg = ActionsMenuContextValue['open']

const defaultRecord = {}

const ActionsMenu = <T = any, ExtraActionsType = {}>({
    actions,
    renderToggler,
    record = defaultRecord,
    className,
    icon,
    resource: resourceProp,
    actionArgs,
    disabled,
    title,
    iconColor,
    iconBackgroundColor,
    ...props
}: MenuActionProps<T, ExtraActionsType>) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
    const flags = useFlags()
    const resource = useResource(resourceProp)
    const parentSelector = useActionsMenuContext()

    const actionsContent = actions(record as T, {
        children: (params) => <ActionsOption {...params} />,
        anchorEl,
        resource,
        flags,
        ...actionArgs,
    })

    if (!actionsContent.length) {
        return null
    }

    const open: ActionsMenuContextValue['open'] = (event) => {
        if (disabled) {
            return
        }
        setAnchorEl(event.currentTarget || (event.target as HTMLElement))
    }
    const close: ActionsMenuContextValue['close'] = (selected) => {
        setAnchorEl(null)
        if (selected) {
            parentSelector?.close?.()
        }
    }

    const Icon = icon || Icons.Options

    const isOpen = Boolean(anchorEl)

    return (
        <ActionsMenuContextProvider
            value={{
                isOpen,
                open,
                close,
            }}
        >
            {renderToggler ? (
                renderToggler(open, isOpen)
            ) : (
                <IconBox
                    iconColor={iconColor}
                    backgroundColor={iconBackgroundColor}
                    title={title}
                    onClick={open}
                    className={className}
                    disabled={disabled}
                >
                    <Icon />
                </IconBox>
            )}
            {/* unmount the menu to prevent the closing animation. */}
            {/* otherwise options can be double clicked and that leads to opening multiple drawers */}
            {isOpen ? (
                <MenuBase
                    autoFocus={false}
                    open
                    anchorEl={anchorEl}
                    onClose={() => close()}
                    {...props}
                >
                    {actionsContent}
                </MenuBase>
            ) : null}
        </ActionsMenuContextProvider>
    )
}

export default ActionsMenu
