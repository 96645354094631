import { type ReactNode } from 'react'

import { useRecordContext } from 'react-admin'

import Icons from 'assets/icons'
import {
    InfoCard,
    InfoCardDetails,
    InfoCardHeader,
    ViewHeader,
    ArrayControllerContextProvider,
    UtilityDrawerForm,
} from 'components'
import { Link } from 'lib'
import { GridContainerColumns, GridItemLayout, IconBox, Section } from 'ui'

import { WoPoCard } from '../../components'

interface PoFormWrapperType {
    children: ReactNode
    [x: string]: any
}
export const PoFormWrapper = ({ children, ...rest }: PoFormWrapperType) => {
    return (
        <UtilityDrawerForm {...rest}>
            <ArrayControllerContextProvider>{children}</ArrayControllerContextProvider>
        </UtilityDrawerForm>
    )
}

export const CanceledWorkOrderPurchaseInvoice = () => {
    const record = useRecordContext()

    if (record.purchaseOrderData) {
        return (
            <InfoCard>
                <InfoCardHeader title="Purchase Order" />
                <InfoCardDetails
                    details={[
                        {
                            label: 'Purchase Order Number',
                            source: ({ purchaseOrderData }) => purchaseOrderData.number,
                        },
                    ]}
                />
            </InfoCard>
        )
    }
    return null
}

const WorkOrderPurchaseInvoice = () => {
    return (
        <Section>
            <ViewHeader title="Purchase Order/Invoice">
                <ViewHeader.Content placement="rightMobile">
                    <Link to="invoice">
                        <IconBox title="See all">
                            <Icons.GridViewOutlined />
                        </IconBox>
                    </Link>
                </ViewHeader.Content>
            </ViewHeader>
            <GridContainerColumns>
                <GridItemLayout>
                    <WoPoCard />
                </GridItemLayout>
            </GridContainerColumns>
        </Section>
    )
}
export default WorkOrderPurchaseInvoice
