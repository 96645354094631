import { type FC } from 'react'

import { inject, observer } from 'mobx-react'
import { useShowContext } from 'react-admin'

import { emptyOptionValue, type AuthStore } from 'core'
import WorkOrderSimpleList from 'pages/WorkOrders/SimpleList'
import { CustomersSection } from 'resources/customers'
import { isUnitArchived, type UnitModel } from 'resources/units'
import { FlexReverse, PageContent } from 'ui'

import TopSection from './TopSection/TopSection'
import UnitDetails from './UnitDetails'

const UnitOverview: FC = inject('auth')(
    observer(({ auth }: { auth: AuthStore }) => {
        const { record, isLoading } = useShowContext<UnitModel>()
        const isArchived = isUnitArchived(record)

        return (
            <>
                <TopSection />
                <PageContent>
                    <FlexReverse>
                        <UnitDetails />
                        {auth.companySettings.hasCustomers && (
                            <CustomersSection
                                customer={record?.customerData}
                                isLoading={isLoading}
                            />
                        )}
                        <WorkOrderSimpleList<UnitModel>
                            createFormInitialValues={(record) => ({
                                unit: record?.id,
                                domicile: record?.domicile,
                                customer: record?.customer,
                            })}
                            filter={(record) => ({
                                unit: [record?.number],
                                unitOwner: auth.companySettings.hasCustomers
                                    ? record?.customerData?.name || emptyOptionValue
                                    : undefined,
                            })}
                            removeCreate={isArchived}
                            removeSeeAllAction
                        />
                    </FlexReverse>
                </PageContent>
            </>
        )
    }),
)

export default UnitOverview
