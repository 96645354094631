import { type FC, type PropsWithChildren } from 'react'

import { inject, observer } from 'mobx-react'
import { useRecordContext } from 'react-admin'

import Icons from 'assets/icons'
import { Header, InnerHeader, ShowHeaderSkeleton, type TabType } from 'components'
import { type AuthStore } from 'core'
import {
    type Action,
    ActionsMenu,
    deleteOneAction,
    downloadOneAction,
    useCanAccess,
} from 'core/actions'
import { poResource, PoSendAction } from 'resources/purchaseOrders'
import {
    useWoCancelAction,
    useWoStatusAction,
    WithIsVendorWO,
    woFields,
    type WorkOrderModel,
    deleteWorkOrderAction,
    pdfOneWOArgs,
    vendorWODeleteDialogAction,
    unaccessibleUnitActionMessage,
    woOperations,
    isWorkOrderBillable,
    WoStatusKeys,
} from 'resources/workOrders'

import { WoInvoiceDrawer, WorkOrderSubtitle } from '../../components'

const WoShowHeader: FC<{ tabs: TabType[] }> = ({ tabs }) => {
    const record = useRecordContext<WorkOrderModel>()
    const actions = useActions()

    if (!record) {
        return <ShowHeaderSkeleton />
    }

    return (
        <InnerHeader
            tabs={tabs}
            tabWidth="124px"
            actions={actions}
        >
            <Header.Info
                avatar={
                    <Header.Avatar defaultImage={<woFields.avatar.RecordIcon record={record} />} />
                }
            >
                <Header.Content>
                    <Header.Title>{record.number}</Header.Title>
                </Header.Content>
                <Header.Content mobilePlacement="bottom">
                    <WorkOrderSubtitle workOrder={record} />
                </Header.Content>
            </Header.Info>
        </InnerHeader>
    )
}
export default WoShowHeader

const InvoiceActionWrapper: FC<PropsWithChildren> = inject('auth')(
    observer(({ auth, children }: { auth: AuthStore } & PropsWithChildren) => {
        if (!auth.companySettings.hasInvoicing) {
            return null
        }

        return <>{children}</>
    }),
)

const setTitleOnDisabled = (record: WorkOrderModel) => {
    if (!record.unitData.isAccessible) {
        return unaccessibleUnitActionMessage
    }

    if (record.status === 'CANCELED') {
        return 'Canceled WOs cannot be deleted'
    }
    if (record.status === 'CLOSED') {
        return 'Closed WOs cannot be deleted'
    }
    return null
}

const useActions = () => {
    const statusAction = useWoStatusAction()
    const cancel = useWoCancelAction()
    const getCanAccess = useCanAccess()
    const canAccess = getCanAccess(woOperations.invoice)

    const actions: Action<WorkOrderModel> = (record, { children, resource }) => {
        const isUnitAccessible = record.unitData.isAccessible
        return [
            statusAction(children),

            isWorkOrderBillable(record) && record.status === WoStatusKeys.CLOSED ? (
                <InvoiceActionWrapper key="create-invoice">
                    <WoInvoiceDrawer record={record}>
                        {(open) =>
                            children({
                                Icon: Icons.ReceiptOutlined,
                                title: 'Create Invoice',
                                disabled: !canAccess.value,
                                titleOnDisabled: canAccess.message,
                                onClick: open,
                            })
                        }
                    </WoInvoiceDrawer>
                </InvoiceActionWrapper>
            ) : null,
            <WithIsVendorWO key="send-purchase-wo">
                {(isVendorWorkOrder) =>
                    isVendorWorkOrder &&
                    record.status !== 'CANCELED' && (
                        <PoSendAction
                            children={(open) =>
                                children({
                                    Icon: Icons.SendOutlined,
                                    title: 'Send Purchase Order',
                                    key: 'send-purchase-wo',
                                    onClick: open,
                                })
                            }
                        />
                    )
                }
            </WithIsVendorWO>,
            <WithIsVendorWO key="export-pdf">
                {(isVendorWorkOrder) =>
                    isVendorWorkOrder && (
                        <ActionsMenu
                            record={record}
                            renderToggler={(open) =>
                                children({
                                    Icon: Icons.Pdf,
                                    key: 'export-pdf',
                                    title: 'Export as PDF',
                                    onClick: (e) => open(e),
                                    disableCloseOnClick: true,
                                })
                            }
                            actions={(params, { children }) => [
                                downloadOneAction({
                                    children: (params) =>
                                        children({
                                            ...params,
                                            Icon: Icons.Pdf,
                                            key: 'export-pdf',
                                            title: 'Export Work Order',
                                        }),
                                    ...pdfOneWOArgs(record),
                                }),
                                downloadOneAction({
                                    children: (params) =>
                                        children({
                                            ...params,
                                            Icon: Icons.Pdf,
                                            key: 'export-purchaseorder',
                                            title: 'Export Purchase Order',
                                        }),
                                    resource: poResource,
                                    id: record.purchaseOrderData.id,
                                    filename: record.purchaseOrderData.number,
                                    type: 'pdf',
                                }),
                            ]}
                        />
                    )
                }
            </WithIsVendorWO>,
            <WithIsVendorWO key="downloadOne">
                {(isVendorWorkOrder) =>
                    !isVendorWorkOrder &&
                    downloadOneAction({
                        children,
                        ...pdfOneWOArgs(record),
                    })
                }
            </WithIsVendorWO>,
            cancel(children),
            <WithIsVendorWO key="deleteOne">
                {(isVendorWorkOrder) =>
                    deleteOneAction({
                        disabled: record.status !== 'OPEN' || !isUnitAccessible,
                        id: record.id,
                        ...(isVendorWorkOrder && record.purchaseOrderData?.totalInvoices
                            ? vendorWODeleteDialogAction()
                            : deleteWorkOrderAction(record, resource!)),
                        children,
                        titleOnDisabled: setTitleOnDisabled(record),
                    })
                }
            </WithIsVendorWO>,
        ]
    }

    return actions
}
