import { type FC } from 'react'

import { useRecordContext } from 'react-admin'

import { MonitorW } from 'components'
import { cssVariables, formatDate } from 'lib'
import { SliderWidgetSkeleton } from 'pages/Dashboard/components/WidgetBase'
import { CreateMeterDrawerToggler } from 'pages/Units/Meters'
import { MeterSource } from 'pages/Units/Meters/components'
import {
    isUnitArchived,
    type MeterModel,
    meterTypesConfig,
    meterValue,
    type UnitMeterTypes,
    type UnitModel,
} from 'resources/units'
import { Anchor, BoxContainer, Boxed, IconElement, StatusText, Text } from 'ui'

interface Props {
    meterType: UnitMeterTypes
    meter: MeterModel
}

const LastKnownMetersWidget: FC<Props> = ({ meterType, meter }) => {
    const record = useRecordContext<UnitModel>()

    if (!record) {
        return <SliderWidgetSkeleton />
    }

    const meterConfig = meterTypesConfig[meterType]
    const isArchived = isUnitArchived(record)

    return (
        <MonitorW>
            <MonitorW.Title>Last Known {meterConfig.name}</MonitorW.Title>
            <MonitorW.Primary>
                <Boxed
                    color={meterConfig.color}
                    bgcolor={meterConfig.bgcolor}
                    disabled={!meter}
                >
                    <IconElement
                        component={meterConfig.Icon}
                        size="18px"
                    />
                </Boxed>
                <Text>{meter ? meterValue(meter.value, meterType) : 'No Entries'}</Text>
            </MonitorW.Primary>
            <MonitorW.BottomContent>
                <Bottom
                    meter={meter}
                    isArchived={isArchived}
                    meterType={meterType}
                />
            </MonitorW.BottomContent>
        </MonitorW>
    )
}

export default LastKnownMetersWidget

interface BottomProps {
    meterType: UnitMeterTypes
    meter: MeterModel | null
    isArchived: boolean
}

const Bottom: FC<BottomProps> = ({ meterType, meter, isArchived }) => {
    if (meter) {
        return (
            <BoxContainer gap="11px">
                <Text>{formatDate(meter.timestamp, (format) => format.shortenedDateTime)}</Text>
                <StatusText
                    statusColor={(theme) => theme.palette.text.primary}
                    sx={{
                        [cssVariables.iconSize]: '12px',
                    }}
                >
                    <MeterSource meter={meter} />
                </StatusText>
            </BoxContainer>
        )
    }

    if (isArchived) {
        return null
    }

    return (
        <CreateMeterDrawerToggler type={meterType}>
            {({ onClick }) => (
                <Anchor
                    onClick={onClick}
                    variant="body1"
                >
                    Add Meter Entry
                </Anchor>
            )}
        </CreateMeterDrawerToggler>
    )
}
