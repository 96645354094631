import { type FC, type ReactElement } from 'react'

import { useListContext } from 'react-admin'

import { useListSnap } from '../ListContext'

import ListFTU, { type ListFTUProps } from './ListFTU'
import ListFilterNoResults from './ListFilterNoResults'

export interface RenderListProps {
    listFTUProps?: ListFTUProps
    children: ReactElement
    disableFTUScreen?: boolean
    renderNoResults?: () => ReactElement
}

const RenderList: FC<RenderListProps> = ({ listFTUProps, children, renderNoResults }) => {
    const { filterValues, total, data, isFetching } = useListContext()
    const snap = useListSnap()

    if (!data || total) {
        return children
    }

    if (renderNoResults) {
        return renderNoResults()
    }

    if (!data.length && isFetching) {
        return null
    }

    const hasFilters = filterValues ? Object.keys(filterValues).length : 0

    if (hasFilters) {
        return <ListFilterNoResults disableFilter={!snap.filter.isApplied} />
    }

    return <ListFTU {...listFTUProps} />
}

export default RenderList
