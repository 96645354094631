import { createContext, type FC, useContext } from 'react'

import { type WithChildrenNode, type ObjectAny } from 'appTypes'

type ContextValue = (name: string, value: any) => any

const defaultValue = (name: string, value: any) => value
const ModifierContext = createContext<ContextValue>(null)

interface Props extends WithChildrenNode {
    value: ContextValue
}

export const ModifierProvider: FC<Props> = ({ children, value }) => {
    const c = useContext(ModifierContext)

    return (
        <ModifierContext.Provider
            value={
                c
                    ? (name, args) => {
                          const valueFromProp = value(name, args)

                          const valueFromContext = c(name, valueFromProp)

                          return typeof valueFromContext === 'undefined'
                              ? valueFromProp
                              : valueFromContext
                      }
                    : value
            }
        >
            {children}
        </ModifierContext.Provider>
    )
}

export const useModifiers = <Config extends ObjectAny>() => {
    const value = useContext(ModifierContext)
    return (value || defaultValue) as <Name extends keyof Config>(
        name: Name,
        args: Config[Name],
    ) => Config[Name]
}

export const createModifiers =
    <Config extends ObjectAny>(modifiers: {
        [K in keyof Config]?: ((args: Config[K]) => Config[K]) | Config[K]
    }) =>
    <Name extends keyof Config>(name: Name, args: Config[Name]) => {
        const config = modifiers[name]

        if (typeof config === 'function') {
            return config(args)
        }

        if (typeof config === 'object') {
            return { ...config, ...args }
        }

        return args
    }
