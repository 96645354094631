import { type ReactElement } from 'react'

import { useRecordContext } from 'react-admin'

import Icons from 'assets/icons'
import { useOpenUtilityDrawer, UtilityDrawerEditor } from 'components'
import { ActionsMenu, type Serializer } from 'core'
import { basePermissions, renderOnPermission } from 'core/permissions'
import {
    getMeterConfig,
    getMetersResource,
    meterTypesForUnit,
    type UnitModel,
    type MeterModel,
    type UnitMeterTypes,
    type MeterConfig,
    hasActiveMeters,
    deleteMeterActionParams,
} from 'resources/units'
import { findUniqueMatch } from 'utils'

import UnitMeterForm from './UnitMeterForm'

interface EditProps {
    meter: MeterModel
    children: (params: {
        onClick: () => void
        disabled?: boolean
        titleOnDisabled?: string
    }) => ReactElement
}

export const EditMeterDrawerToggler = renderOnPermission(
    ({ children, meter }: EditProps) => {
        const open = useOpenUtilityDrawer()
        const unit = useRecordContext<UnitModel>()

        if (!meter) {
            return null
        }

        const id = meter.id

        const titleOnDisabled =
            (meter.source === 'WORK_ORDER' &&
                'This meter reading originates from a Work Order and can be updated through it') ||
            (meter.isFromTelematics &&
                'This meter reading originates from a Telematics system and can not be updated')

        return children({
            onClick: () =>
                open({
                    extraArgs: {
                        resource: getMetersResource(unit.id),
                        id,
                    },
                    drawerArgs: {
                        title: <EditMeterTitle />,
                        renderContent: () => <EditForm />,
                        renderWrapper: (params) => (
                            <UtilityDrawerEditor
                                {...params}
                                serializer={meterSerializer}
                            />
                        ),
                        renderTopRight: (render) => render(deleteMeterActionParams),
                    },
                }),
            disabled: Boolean(meter.source !== 'MANUAL'),
            titleOnDisabled,
        })
    },
    ({ permissions }) => permissions[basePermissions.update],
)

const EditForm = () => {
    const meter = useRecordContext<MeterModel>()

    if (!meter) {
        return null
    }

    return <UnitMeterForm type={meter.type} />
}

const EditMeterTitle = () => {
    const meter = useRecordContext<MeterModel>()

    if (!meter) {
        return null
    }

    return <>Edit {getMeterConfig(meter.type).name} Entry</>
}

interface CreateProps {
    children: (
        params: {
            onClick: (e) => void
            disabled?: boolean
            titleOnDisabled?: string
            disableCloseOnClick?: true
        },
        single: MeterConfig | null,
    ) => ReactElement
    type?: UnitMeterTypes
}

export const CreateMeterDrawerToggler = renderOnPermission(
    ({ children, type }: CreateProps) => {
        const unit = useRecordContext<UnitModel>()
        const open = useOpenUtilityDrawer()
        const hasActive = hasActiveMeters(unit?.settings)

        if (!unit?.settings) {
            return children({ onClick: () => null, disabled: true }, null)
        }

        const add = (type: UnitMeterTypes) => {
            open({
                extraArgs: {
                    resource: getMetersResource(unit.id),
                },
                drawerArgs: {
                    title: `Add ${getMeterConfig(type).name} Entry`,
                    renderContent: () => <UnitMeterForm type={type} />,
                    renderWrapper: (params) => (
                        <UtilityDrawerEditor
                            {...params}
                            serializer={meterSerializer}
                            defaultValues={
                                {
                                    type,
                                } satisfies Partial<MeterModel>
                            }
                        />
                    ),
                    renderBottomRight: (render) => render({ icon: <Icons.Add />, label: 'Add' }),
                },
            })
        }

        if (type) {
            return children({ onClick: () => add(type) }, null)
        }

        const single = findUniqueMatch(meterTypesForUnit, (meterType) => {
            return unit.settings[meterType]?.tracked
        })

        const singleConfig = single && getMeterConfig(single)

        if (singleConfig) {
            return children({ onClick: () => add(single) }, singleConfig)
        }

        if (!hasActive) {
            return children(
                { onClick: null, disabled: true, titleOnDisabled: 'No Tracked Meters' },
                null,
            )
        }

        return (
            <ActionsMenu
                renderToggler={(open) =>
                    children({ onClick: open, disableCloseOnClick: true }, singleConfig)
                }
                actions={(_, { children }) => {
                    return meterTypesForUnit.map((type) => {
                        const meterConfig = getMeterConfig(type)
                        if (!unit.settings[type]?.tracked) {
                            return null
                        }

                        return children({
                            title: meterConfig.name,
                            key: type,
                            onClick: () => add(type),
                            Icon: meterConfig.Icon,
                        })
                    })
                }}
            />
        )
    },
    ({ permissions }) => permissions[basePermissions.create],
)

const meterSerializer: Serializer<MeterModel> = [
    { name: 'type', parse: 'disableOnEdit' },
    { name: 'value', parse: 'number' },
    { name: 'timestamp', parse: 'dateTime' },
]
