import { type ReactElement, useEffect, useRef, type FC, useState, useMemo } from 'react'

import { useEventCallback } from '@mui/material'
import { gridClasses, DataGridPremium, useGridApiRef } from '@mui/x-data-grid-premium'
import { useListContext } from 'react-admin'

import { type DataRecord } from 'appTypes'
import { useResourcePreferences } from 'core/context'
import { alpha, classes, globalClassNames } from 'lib'
import { typographyClasses, Box, Skeleton } from 'ui'
import { findClosestParent } from 'utils'

import ColumnMenu from './ColumnMenu'
import Pagination from './Pagination'
import { useColumnsContext } from './controls'
import { type DatagridProps } from './types'
import { actionsField, checkboxField, datagridClasses } from './utils'

const Datagrid: FC<DatagridProps> = <RecordType extends DataRecord = any>({
    checkboxSelection = true,
    disableSelectRecord,
    hideFooter = false,
    disableColumnReorder = false,
    disableColumnResize = false,
}: DatagridProps<RecordType>) => {
    const listContext = useListContext()
    const { data, onSelect, selectedIds, page, setPage, perPage, setPerPage, total } = listContext

    const preferences = useResourcePreferences()

    const movedColumn = useRef<{ field: string; index: number } | null>(null)

    const {
        columns,
        visibilityModel,
        updateOrder,
        setPinnedColumns,
        pinnedColumns,
        setSize,
        setVisibilityModel,
    } = useColumnsContext()

    const apiRef = useGridApiRef()

    if (!columns) {
        return null
    }

    if (!data) {
        return loadingSkeleton
    }

    const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value)
    }
    const handleRowsChange = (event: React.ChangeEvent<unknown>, callback) => {
        preferences.update({
            perPage: Number(callback.props.value),
        })
        setPerPage(callback.props.value)
    }

    return (
        <Container
            disableColumnResize={disableColumnResize}
            onDrop={() => {
                if (!movedColumn.current) {
                    return
                }
                const fields = apiRef.current.state.columns.all.filter(
                    (field) => field !== actionsField && field !== checkboxField,
                )

                updateOrder(fields)

                movedColumn.current = null
            }}
            rowsLength={data.length}
            hideFooter={hideFooter}
        >
            <DataGridPremium
                apiRef={apiRef}
                disableSelectionOnClick
                onColumnOrderChange={(params) => {
                    movedColumn.current = {
                        field: params.field,
                        index: params.targetIndex,
                    }
                }}
                rowHeight={56}
                hideFooterSelectedRowCount
                components={{
                    Pagination,
                    ColumnMenu,
                }}
                disableColumnResize={disableColumnResize}
                pagination
                disableColumnReorder={disableColumnReorder}
                hideFooter={hideFooter}
                isRowSelectable={
                    disableSelectRecord ? ({ row }) => !disableSelectRecord(row) : undefined
                }
                pinnedColumns={pinnedColumns}
                onPinnedColumnsChange={setPinnedColumns}
                onColumnWidthChange={({ colDef }) => {
                    setSize(colDef)
                }}
                onColumnVisibilityModelChange={setVisibilityModel}
                componentsProps={{
                    pagination: {
                        count: total,
                        component: 'div',
                        page: page - 1,
                        onPageChange: handlePageChange,
                        rowsPerPage: perPage,
                        rowsPerPageOptions: [5, 10, 25, 50, 100],
                        labelDisplayedRows: () =>
                            `${page * perPage - perPage + 1}-\
                        ${total < page * perPage ? total : page * perPage} of ${total}`,
                        onRowsPerPageChange: handleRowsChange,
                    },
                    columnMenu: {
                        hideable: true,
                    },
                }}
                sx={{
                    flexGrow: 0,
                    width: '100%',
                    bgcolor: 'white',
                    color: 'text.main',
                    [`& .${gridClasses.checkboxInput}`]: {
                        padding: '0px !important',
                    },
                    [`& .${gridClasses.cell}`]: {
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        '&:focus-within': {
                            outline: 'none',
                        },
                        [`& .${typographyClasses.root}`]: {
                            whiteSpace: 'inherit',
                            overflow: 'inherit',
                            textOverflow: 'inherit',
                        },
                    },
                    [`& .${gridClasses.cellContent}`]: {
                        width: '100%',
                        '&:empty::before': {
                            content: '"-"',
                            display: 'inline-block',
                        },
                    },
                    [`& .${gridClasses.columnHeader}.${gridClasses['columnHeader--sortable']}`]: {
                        padding: '16px',
                    },
                    [`& .${gridClasses.columnHeader}:focus-within`]: {
                        outline: 'none',
                    },
                    [`& .${gridClasses.footerContainer}`]: {
                        justifyContent: 'flex-end',
                    },
                    [`& .${classes.selected}`]: (theme) => ({
                        backgroundColor: `${alpha(theme.palette.primary.main, 0.08)} !important`,
                    }),
                    // TODO: custom cell content does not inherit the styles from the mui datagrid
                    [`.${datagridClasses['cell--textRight']} .${datagridClasses.cellContent}`]: {
                        textAlign: 'right',
                    },
                }}
                rows={data}
                rowCount={total}
                columns={columns}
                columnVisibilityModel={visibilityModel}
                paginationMode="server"
                rowsPerPageOptions={[1, 5, 10, 25, 100]}
                pageSize={perPage}
                checkboxSelection={checkboxSelection}
                selectionModel={checkboxSelection ? selectedIds : defaultSelectionModel}
                onSelectionModelChange={(selectionModel) => {
                    try {
                        onSelect(selectionModel)
                    } catch {
                        // sometimes this throws an error when selectedIds change during render
                    }
                }}
                localeText={{
                    toolbarColumns: 'MANAGE COLUMNS',
                    checkboxSelectionHeaderName: 'Checkbox Selection',
                }}
            />
        </Container>
    )
}

export default Datagrid

const defaultSelectionModel = []

export const datagridEmptyCell = <div className={globalClassNames.displayNone}>-</div>

interface ContainerProps {
    onDrop: () => void
    children: ReactElement
    rowsLength: number
    hideFooter: boolean
    disableColumnResize: boolean
}

const Container: FC<ContainerProps> = ({
    children,
    onDrop: onDropProp,
    rowsLength,
    hideFooter,
    disableColumnResize,
}) => {
    const containerRef = useRef<HTMLDivElement>(null)
    const [containerHeight, setH] = useState<number>()
    const onDrop = useEventCallback(onDropProp)

    useEffect(() => {
        // onColumnOrderChange executes on each column movement. We want to execute it only when it stops
        // use drop instead of dragend, because dragend fires on cancel as well
        const drop = (event: Event) => {
            const target = event.target as HTMLElement
            if (
                !target ||
                !findClosestParent(target, (element) =>
                    element.classList.contains(datagridClasses.columnHeaderTitleContainer),
                ) ||
                target.classList.contains(datagridClasses.cell)
            ) {
                return
            }

            onDrop()
        }

        const el = containerRef.current

        const onResize = () => {
            const height = getHeightWithoutPadding(el)
            setH(height)
        }

        const resizeObserver = new ResizeObserver(onResize)
        resizeObserver.observe(el)

        el.addEventListener('drop', drop)

        return () => {
            el.removeEventListener('drop', drop)
            resizeObserver.disconnect()
        }
    }, [])

    const footerH = hideFooter ? 0 : 53
    // + 20 for horizontal the scrollbar
    // + 2 extra, sometimes vertical scrollbar appears
    const outerHeight = footerH + rowHeight + 2 + (disableColumnResize ? 0 : 20)
    const minH = 10 * rowHeight + outerHeight
    const contentHeight = rowsLength * rowHeight
    const calculatedHeight = contentHeight + outerHeight

    const height = getHeight(minH, calculatedHeight, containerHeight)

    // when the scrollbar from the rows toggles
    // the right pinned headers sometimes are not aligned with the body
    // change the key when the height changes to force the component to mount
    // maybe fixed in newer versions of mui
    const key = useMemo(() => Date.now(), [height])

    return (
        <Box
            ref={containerRef}
            minHeight="100px"
            display="flex"
            flexDirection="column"
            flexGrow={1}
        >
            <Box
                sx={{ height }}
                key={key}
            >
                {children}
            </Box>
        </Box>
    )
}

const getHeight = (minH: number, contentHeight: number, containerHeight: number) => {
    // when there are lots of data, the height is the biggest one of remaining space and minH
    const max = Math.max(minH, containerHeight || 100)
    return Math.min(max, contentHeight)
}

const rowHeight = 56

function getHeightWithoutPadding(element: HTMLElement) {
    const style = window.getComputedStyle(element)
    const clientHeight = element.scrollHeight
    const paddingTop = parseFloat(style.paddingTop)
    const paddingBottom = parseFloat(style.paddingBottom)
    return clientHeight - paddingTop - paddingBottom
}

const col = (key: number) => (
    <Skeleton
        key={key}
        variant="text"
        sx={{ fontSize: '10px', flexGrow: 1 }}
    />
)
const row = Array.from({ length: 5 }).map((_, i) => col(i))

const loadingSkeleton = (
    <Box
        display="flex"
        gap="40px"
        flexDirection="column"
        width="100%"
        p="30px"
        boxSizing="border-box"
        bgcolor={(theme) => theme.palette.white}
        border="1px solid rgb(224, 224, 224)"
    >
        {Array.from({ length: 10 }).map((_, i) => (
            <Box
                width="100%"
                display="flex"
                gap="30px"
                key={i}
            >
                {row}
            </Box>
        ))}
    </Box>
)
