import { useShowContext } from 'react-admin'

import images from 'assets/images'
import {
    type CardListConfig,
    type DatagridColumnsProps,
    ListAvatar,
    formatMoney,
    ResourceLinkButton,
    datagridAvatarColumn,
    ListController,
    ListUi,
    type ListControllerProps,
} from 'components'
import { urls } from 'configs'
import { useCreateResourcePath, type Action, type SortPayload } from 'core'
import { globalClassNames, formatDate } from 'lib'
import { type PartModel, partFields } from 'resources/parts'
import {
    type LineItemsModel,
    type ReceiptItemModel,
    type PurchaseOrderModel,
    type ReceiptModel,
    lineItemsFields,
    poFields,
    PoStatusKeys,
} from 'resources/purchaseOrders'
import { uomFields } from 'resources/unitsOfMeasure'
import { ck34Fields } from 'resources/vmrs'
import { Box, Tooltip, PageContent } from 'ui'

import { lineItemsActions } from '../../actions'
import { formatPOItemTotal } from '../utils'

import AddItemsFTUButton from './AddItemsFTUButton'
import LineItemsHeader from './LineItemsHeader'
import LineItemsResource from './LineItemsResource'

const LineItemsList = ({ receipt }: { receipt?: ReceiptModel }) => {
    const { record } = useShowContext<PurchaseOrderModel>()
    const createPath = useCreateResourcePath()

    const navigateTo = (item: LineItemsModel & ReceiptItemModel) =>
        createPath({
            resource: urls.inventory,
            type: 'edit',
            id: item.inventoryItem,
        })

    const configVariant: ConfigVariant =
        record?.type === 'CREDIT' ? 'credit' : receipt ? 'receipt' : 'standard'

    const preferencesName =
        configVariant === 'credit' ? 'purchase-order-return-items' : 'purchase-order-line-items'

    return (
        <PageContent>
            <LineItemsResource
                id={record?.id}
                receipt={receipt?.id}
            >
                <ListController
                    sort={defaultSort(configVariant)}
                    preferencesName={preferencesName}
                    key={receipt?.id || record?.id}
                    sorts={getSorts(configVariant)}
                >
                    <LineItemsHeader receipt={receipt} />
                    <ListUi
                        disableExportButton
                        columnsCfg={getColumnsConfig(configVariant, record)}
                        cardsCfg={getCardConfig(configVariant, record, navigateTo)}
                        listFTUProps={{
                            imageSrc: images.noLineItems,
                            title: 'No Items Added',
                            action: <AddItemsFTUButton />,
                        }}
                    />
                </ListController>
            </LineItemsResource>
        </PageContent>
    )
}
export default LineItemsList

type SortCfgType = LineItemsModel &
    ReceiptItemModel & {
        priceOnOrder: number
        priceReceived: number
    } & {
        [key in `part${Capitalize<keyof PartModel>}`]: never
    }

type VariantConfigs = {
    standard: LineItemsModel
    credit: LineItemsModel
    receipt: ReceiptItemModel
}
type ConfigVariant = keyof VariantConfigs

const defaultSort = (variant: ConfigVariant): SortPayload<LineItemsModel & ReceiptItemModel> => ({
    field: variant === 'receipt' ? 'type' : 'created',
    order: 'DESC',
})

const getSorts = (variant: ConfigVariant) => {
    const sorts: ListControllerProps<SortCfgType>['sorts'] = [
        { id: 'partNumber', label: 'Part Number' },
        { id: 'partDescription', label: 'Part Description' },
        { id: 'partUniversalProductCode', label: 'UPC' },
        { id: 'partManufacturerPartNumber', label: 'Manufacturer Part Number' },
        ck34Fields.self.sort({ id: 'partManufacturer' }),
        { id: 'created', label: 'Created on' },
        variant === 'receipt' ? { id: 'type', label: 'Status' } : null,
        uomFields.self.sort({ id: 'partUnitOfMeasure' }),

        variant === 'credit'
            ? { id: 'quantity', label: 'Return Quantity' }
            : { id: variant === 'receipt' ? 'quantityOnOrder' : 'quantity', label: 'QTY on Order' },

        variant === 'receipt' ? { id: 'quantity', label: 'QTY Received' } : null,
        variant === 'credit'
            ? { id: 'price', label: 'Cost' }
            : {
                  id: variant === 'receipt' ? 'priceOnOrder' : 'price',
                  label: 'Cost on Order',
              },
        variant === 'receipt' ? { id: 'priceReceived', label: 'Cost Received' } : null,
        {
            id: 'total',
            label: 'Total Cost',
        },
    ]

    return sorts
}

type POCardListConfig = CardListConfig<
    LineItemsModel &
        ReceiptItemModel & {
            universalProductCode: string
            manufacturerPartNumber: string
            unitOfMeasure: never
            quantityReceived: string
            priceReceived: string
        }
>

const getCardConfig = (
    variant: ConfigVariant,
    poRecord: PurchaseOrderModel,
    navigateTo,
): POCardListConfig => {
    if (!poRecord) {
        return {} as any
    }
    // array filter(Boolean) removes the types from 'source', we have to separate it to variable
    const details: POCardListConfig['details'] = [
        {
            source: 'universalProductCode',
            label: 'UPC',
            render: (value, record) => record.partData.universalProductCode,
        },
        {
            source: 'manufacturerPartNumber',
            label: 'Manufacturer Part Number',
            render: (v, record) => record.partData.manufacturerPartNumber,
        },
        poRecord.type === 'STANDARD' && {
            source: 'type',
            label: 'Status',
            render: (v, record) =>
                variant === 'receipt'
                    ? lineItemsFields.status.value(record as ReceiptItemModel)
                    : poFields.status.value(poRecord),
        },
        uomFields.self.dataCardRow({
            dataToRecord: (data: LineItemsModel) => data.partData.unitOfMeasureData,
        }),
        poRecord.type === 'STANDARD' && {
            source: 'quantity',
            label: 'QTY on Order',
            render: (v, record) =>
                variant === 'receipt' ? record.quantityOnOrder : record.quantity,
        },
        poRecord.type === 'STANDARD' && {
            source: 'quantityReceived',
            label: 'QTY Received',
            render: (v, record) =>
                variant === 'receipt' && record.type !== 'CANCEL' ? record.quantity : null,
        },
        poRecord.type === 'STANDARD' && {
            source: 'price',
            label: 'Cost on Order',
            render: (value, record) =>
                formatMoney(variant === 'receipt' ? record.priceOnOrder : value),
        },
        poRecord.type === 'STANDARD' && {
            source: 'priceReceived',
            label: 'Cost Received',
            render: (v, record) =>
                variant === 'receipt' && record.type !== 'CANCEL'
                    ? formatMoney(record.price)
                    : null,
        },
        poRecord.type === 'STANDARD' && {
            source: 'total',
            label: 'Total Cost',
            render: (value) => formatPOItemTotal(value),
        },

        poRecord.type === 'CREDIT' && {
            source: 'quantity',
            label: 'Return Quantity',
            render: (value) => value || 0,
        },
        poRecord.type === 'CREDIT' && {
            source: 'price',
            label: 'Cost',
            render: (value) => formatMoney(value),
        },
        poRecord.type === 'CREDIT' && {
            source: 'total',
            label: 'Total',
            render: (value) => formatPOItemTotal(value),
        },
    ]
    return {
        titleSource: (record) => record.partData.number,
        titleLink: (record) => navigateTo(record),
        subTitleSource: (record) => record.partData.description,
        imageSource: (record) => record.partData.image,
        defaultImage: <partFields.avatar.Icon />,
        details: details.filter(Boolean),
        actions:
            poRecord.status !== PoStatusKeys.OPEN || variant === 'receipt'
                ? null
                : (lineItemsActions as Action),
    }
}

type POColumnsConfig = DatagridColumnsProps<
    LineItemsModel &
        ReceiptItemModel & {
            image: string
            description: string
            partNumber: string
            universalProductCode: string
            manufacturerPartNumber: string
            unitOfMeasure: never
            status: string
            quantityReceived: string
            priceReceived: string
            manufacturer: string
        }
>

const getColumnsConfig = (
    variant: ConfigVariant,
    poRecord: PurchaseOrderModel,
): POColumnsConfig => {
    if (!poRecord) {
        return { columns: [], mainField: 'partNumber' }
    }

    const columns: POColumnsConfig['columns'] = [
        datagridAvatarColumn({
            field: 'image',
            headerName: 'Picture',
            avatar: (record) => (
                <ListAvatar
                    id={record.inventoryItem}
                    imageSrc={record.partData.image}
                    resource={urls.inventory}
                    defaultImage={<partFields.avatar.Icon />}
                />
            ),
        }),
        {
            field: 'partNumber',
            headerName: 'Part Number',
            renderCell: ({ row }) => (
                <ResourceLinkButton
                    to={{
                        resource: urls.inventory,
                        type: 'edit',
                        id: row.inventoryItem,
                    }}
                >
                    {row.partData.number}
                </ResourceLinkButton>
            ),
        },
        {
            field: 'description',
            headerName: 'Part Description',
            renderCell: ({ row }) => {
                const description = row.partData.description
                return description ? (
                    <Tooltip title={description}>
                        <Box className={globalClassNames.ellipsis}>{description}</Box>
                    </Tooltip>
                ) : (
                    description
                )
            },
        },
        {
            field: 'universalProductCode',
            headerName: 'UPC',
            valueGetter: ({ row }) => row.partData.universalProductCode,
        },
        {
            field: 'manufacturerPartNumber',
            headerName: 'Manufacturer Part Number',
            valueGetter: ({ row }) => row.partData.manufacturerPartNumber,
        },
        ck34Fields.self.column({ dataToRecord: (record) => record.partData.manufacturerData }),
        {
            field: 'created',
            headerName: 'Created on',
            valueGetter: ({ row }) => {
                return formatDate(row.created, (dateFormats) => dateFormats.shortenedDateTime)
            },
        },
        poRecord.type === 'STANDARD' && {
            field: 'type',
            headerName: 'Status',
            renderCell: ({ row }) =>
                variant === 'receipt'
                    ? lineItemsFields.status.value(row as ReceiptItemModel)
                    : poFields.status.value(poRecord),
        },
        uomFields.self.tableColumn({
            dataToRecord: (data: LineItemsModel) => data.partData.unitOfMeasureData,
        }),
        poRecord.type === 'STANDARD' && {
            field: 'quantity',
            headerName: 'QTY on Order',
            valueGetter: ({ row, value }) => (variant === 'receipt' ? row.quantityOnOrder : value),
        },
        poRecord.type === 'STANDARD' && {
            field: 'quantityReceived',
            headerName: 'QTY Received',
            valueGetter: ({ row }) =>
                variant === 'receipt' && row.type !== 'CANCEL' ? row.quantity : null,
        },
        poRecord.type === 'STANDARD' && {
            field: 'price',
            headerName: 'Cost on Order',
            align: 'right',
            renderCell: ({ value, row }) =>
                formatMoney(variant === 'receipt' ? row.priceOnOrder : value),
        },
        poRecord.type === 'STANDARD' && {
            field: 'priceReceived',
            headerName: 'Cost Received',
            align: 'right',
            renderCell: ({ row }) =>
                variant === 'receipt' && row.type !== 'CANCEL' ? formatMoney(row.price) : null,
        },
        poRecord.type === 'STANDARD' && {
            field: 'total',
            headerName: 'Total Cost',
            align: 'right',
            renderCell: ({ value }) => formatPOItemTotal(value),
        },

        poRecord.type === 'CREDIT' && {
            field: 'quantity',
            headerName: 'Return Quantity',
            valueGetter: ({ value }) => value || 0,
        },
        poRecord.type === 'CREDIT' && {
            field: 'price',
            align: 'right',
            headerName: 'Cost',
            renderCell: ({ value }) => formatMoney(value),
        },
        poRecord.type === 'CREDIT' && {
            field: 'total',
            align: 'right',
            headerName: 'Total',
            renderCell: ({ value }) => formatPOItemTotal(value),
        },
    ]

    return {
        resetColumns: {
            created: false,
        },
        pinnedColumns: {
            right: ['total'],
        },
        mainField: 'partNumber',
        checkboxSelection: false,
        columns,
        actions:
            poRecord.status !== PoStatusKeys.OPEN || variant === 'receipt'
                ? null
                : lineItemsActions,
        avatarSource: 'image',
    }
}
