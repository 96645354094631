import { useRecordContext } from 'react-admin'

import { formatMoney, InfoCard, InfoCardDetails, InfoCardHeader } from 'components'
import { PoExportActionIconButton, PoSendActionIconButton } from 'resources/purchaseOrders'
import { type WorkOrderModel } from 'resources/workOrders'
import { Spacer } from 'ui'

const WoPoCard = () => {
    const record = useRecordContext<WorkOrderModel>()
    const po = record.purchaseOrderData

    return (
        <InfoCard>
            <InfoCardHeader
                title={po.number}
                action={
                    <Spacer>
                        <PoSendActionIconButton po={po} />
                        <PoExportActionIconButton po={po} />
                    </Spacer>
                }
            />
            <InfoCardDetails
                details={[
                    {
                        label: 'purchase order Total',
                        source: () => formatMoney(po.total),
                    },
                    {
                        label: 'Invoices Total',
                        source: () => formatMoney(po.totalInvoices),
                    },
                    {
                        label: 'Payments Total',
                        source: () => formatMoney(po.totalPayments),
                    },
                ]}
            />
        </InfoCard>
    )
}

export default WoPoCard
