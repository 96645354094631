import { useRecordContext } from 'react-admin'

import { ListFtuCardContainer, NoResultsCard } from 'components'
import { urls } from 'configs'
import { isUnitArchived, type UnitModel } from 'resources/units'
import { LinkButton, Typography } from 'ui'
import { pathJoin } from 'utils'

const NoTrackedMeters = () => {
    const unit = useRecordContext<UnitModel>()
    const isArchived = isUnitArchived(unit)

    return (
        <ListFtuCardContainer
            container
            sx={{ flex: 1 }}
        >
            <NoResultsCard
                transparent
                height="auto"
                title="No Meters Are Being Tracked"
                subtitle={
                    isArchived ? null : (
                        <Typography color="inherit">
                            Choose which meters you want to monitor to
                            <br />
                            start tracking performance
                        </Typography>
                    )
                }
                imageSrc={(images) => images.listEmpty}
                imageProps={{
                    mb: {
                        sm: '32px',
                        xs: '16px',
                    },
                    width: '260px',
                }}
                action={
                    isArchived ? null : (
                        <LinkButton
                            to={pathJoin(urls.units, unit.id, 'settings')}
                            variant="body2"
                        >
                            Go to settings
                        </LinkButton>
                    )
                }
            />
        </ListFtuCardContainer>
    )
}

export default NoTrackedMeters
