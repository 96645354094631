import {
    type CardListConfig,
    type DatagridColumnsProps,
    LinkArrowButton,
    ListFilterNoResults,
    ListController,
    ListUi,
    type ListControllerProps,
} from 'components'
import { urls } from 'configs'
import { ResourceContextProviderWithClearEffect, type SortPayload } from 'core'
import { globalClassNames } from 'lib'
import { type AssignmentModel, assignmentsResource, assignmentFields } from 'resources/assignments'
import { createdField } from 'resources/base'
import { customerFields } from 'resources/customers'
import { ArchivedUnitBadge, isUnitArchived, unitFields, type UnitModel } from 'resources/units'
import { ck2Fields } from 'resources/vmrs'
import { BoxContainer, LinkButton, PageContent, StyledElement } from 'ui'
import { pathJoin } from 'utils'

import { ReportsHeader } from '../components'

const defaultSort: SortPayload<AssignmentModel> = {
    field: 'start',
    order: 'DESC',
}

const Assignments = () => {
    return (
        <ResourceContextProviderWithClearEffect value={assignmentsResource}>
            <ListController
                filters={filters}
                sorts={sorts}
                sort={defaultSort}
            >
                <ReportsHeader>Assignments</ReportsHeader>
                <PageContent>
                    <ListUi
                        columnsCfg={columnsCfg}
                        cardsCfg={cardsCfg}
                        renderNoResults={() => (
                            <ListFilterNoResults
                                disableFilter
                                subtitle={
                                    <>
                                        No results match your criteria.
                                        <br />
                                        Try modifying the search or filters.
                                    </>
                                }
                            />
                        )}
                    />
                </PageContent>
            </ListController>
        </ResourceContextProviderWithClearEffect>
    )
}

export default Assignments

const navigateTo = (unit: UnitModel) => pathJoin(urls.units, unit.id)

const cardsCfg: CardListConfig<AssignmentModel & { vmrsEquipmentCategory: string }> = {
    titleSource: (record) => (
        <LinkButton to={navigateTo(record.unitData)}>
            <BoxContainer>
                <StyledElement className={globalClassNames.ellipsis}>
                    {record.unitData.number}
                </StyledElement>
                {isUnitArchived(record.unitData) && <ArchivedUnitBadge />}
            </BoxContainer>
        </LinkButton>
    ),
    disableTitleLink: true,
    defaultImage: null,
    details: [
        ck2Fields.self.cardRow({
            id: 'vmrsEquipmentCategory',
            dataToRecord: (record: AssignmentModel) => record.unitData.vmrsEquipmentCategoryData,
        }),
        customerFields.self.dataCardRow({
            dataToRecord: (record) => record.customerData,
            label: 'Customer Name',
            withLink: true,
        }),
        assignmentFields.startDate.dataCardRow(),
        assignmentFields.endDate.dataCardRow(),
        assignmentFields.status.dataCardRow(),
        assignmentFields.accumulatedCost.dataCardRow(),
    ],
    action: (record) => <LinkArrowButton path={navigateTo(record.unitData)} />,
}

const columnsCfg: DatagridColumnsProps<AssignmentModel & { vmrsEquipmentCategory: string }> = {
    resetColumns: {
        [createdField.source]: false,
    },
    mainField: unitFields.self.source,
    checkboxSelection: false,
    columns: [
        {
            field: unitFields.self.source,
            headerName: unitFields.self.label,
            renderCell: ({ row }) => {
                return (
                    <LinkButton to={navigateTo(row.unitData)}>
                        <BoxContainer>
                            <StyledElement className={globalClassNames.ellipsis}>
                                {row.unitData.number}
                            </StyledElement>
                            {isUnitArchived(row.unitData) && <ArchivedUnitBadge />}
                        </BoxContainer>
                    </LinkButton>
                )
            },
        },
        ck2Fields.self.column({
            id: 'vmrsEquipmentCategory',
            dataToRecord: (record: AssignmentModel) => record.unitData.vmrsEquipmentCategoryData,
        }),
        customerFields.self.tableColumn({
            dataToRecord: (record) => record.customerData,
            label: 'Customer Name',
            withLink: true,
        }),
        assignmentFields.startDate.tableColumn(),
        assignmentFields.endDate.tableColumn(),
        assignmentFields.status.tableColumn(),
        createdField.tableColumn({ dataToValue: (record: AssignmentModel) => record.created }),
        assignmentFields.accumulatedCost.tableColumn(),
    ],
    actions: null,
}

const filters: ListControllerProps<AssignmentModel & { vmrsEquipmentCategory: string }>['filters'] =
    [
        unitFields.self.filter(),
        ck2Fields.self.filter({ id: 'vmrsEquipmentCategory' }),
        customerFields.self.filter({
            label: 'Customer Name',
        }),
        assignmentFields.startDate.filter(),
        assignmentFields.endDate.filter(),
        assignmentFields.status.filter(),
        assignmentFields.accumulatedCost.filter(),
        createdField.filter(),
    ]

const sorts: ListControllerProps<AssignmentModel & { vmrsEquipmentCategory: string }>['sorts'] = [
    unitFields.self.sort(),
    ck2Fields.self.sort({ id: 'vmrsEquipmentCategory' }),
    customerFields.self.sort({
        label: 'Customer Name',
    }),
    assignmentFields.startDate.sort(),
    assignmentFields.endDate.sort(),
    assignmentFields.accumulatedCost.sort(),
    createdField.sort(),
]
