import { type FC } from 'react'

import { useRecordContext } from 'react-admin'

import Icons from 'assets/icons'
import {
    type CardListConfig,
    type DatagridColumnsProps,
    ListAvatar,
    ListController,
    datagridAvatarColumn,
    type ListControllerProps,
    ListUi,
} from 'components'
import { ResourceContextProviderWithClearEffect, type SortPayload } from 'core'
import {
    type CustomerModel,
    type CustomerPricingModel,
    customersResource,
} from 'resources/customers'
import { ptLaborFields, ptPartFields, ptServiceFields, ptTaxFields } from 'resources/pricingTiers'
import { shopFields } from 'resources/shops'
import { PageContent } from 'ui'

import { CustomerPricingDrawerToggler } from './CustomerPricingDrawerToggler'
import ListHeader from './CustomerPricingListHeader'
import { customerPricingResourceName } from './utils'

const defaultSort: SortPayload<CustomerPricingModel> = {
    field: shopFields.self.source,
    order: 'ASC',
}

const sorts: ListControllerProps<CustomerPricingModel>['sorts'] = [
    shopFields.self.sort(),
    ptPartFields.self.sort(),
    ptLaborFields.self.sort(),
    ptServiceFields.self.sort(),
    ptTaxFields.self.sort(),
]

const filters: ListControllerProps<CustomerPricingModel>['filters'] = [
    ptPartFields.self.filter(),
    ptLaborFields.self.filter(),
    ptServiceFields.self.filter(),
    ptTaxFields.self.filter(),
]

const columnsConfig: DatagridColumnsProps<CustomerPricingModel> = {
    checkboxSelection: false,
    mainField: shopFields.self.source,
    avatarSource: shopFields.avatar.source as keyof CustomerPricingModel,
    columns: [
        datagridAvatarColumn({
            field: shopFields.avatar.source as keyof CustomerPricingModel,
            headerName: shopFields.avatar.label,
            avatar: (record) => (
                <ListAvatar
                    id={record.shop}
                    disableLink
                    imageSrc={record.shopData.logo}
                    defaultImage={<shopFields.avatar.Icon record={record.shopData} />}
                    color={record.shopData.avatarColor}
                    avatarOpacity={1}
                />
            ),
        }),
        shopFields.self.tableColumn({
            dataToRecord: (record: CustomerPricingModel) => record.shopData,
        }),
        ptPartFields.self.tableColumn({
            dataToRecord: (record: CustomerPricingModel) => record.partTierData,
        }),
        ptLaborFields.self.tableColumn({
            dataToRecord: (record: CustomerPricingModel) => record.laborTierData,
        }),
        ptServiceFields.self.tableColumn({
            dataToRecord: (record: CustomerPricingModel) => record.serviceTierData,
        }),
        ptTaxFields.self.tableColumn({
            dataToRecord: (record: CustomerPricingModel) => record.taxTierData,
            isTaxExempt: (record: CustomerPricingModel) => record.isTaxExempt,
        }),
    ],
    actions: ({ row }, { children, resource }) => [
        <CustomerPricingDrawerToggler
            key="edit"
            record={row}
            resource={resource.resource}
        >
            {({ onClick }) =>
                children({
                    title: 'Edit',
                    Icon: Icons.Edit,
                    onClick,
                })
            }
        </CustomerPricingDrawerToggler>,
    ],
}

const cardsConfig: CardListConfig<CustomerPricingModel> = {
    titleSource: (record) => shopFields.name.value(record.shopData),
    defaultImage: (record) => <shopFields.avatar.Icon record={record.shopData} />,
    avatarColor: (t, record) => record.shopData.avatarColor,
    avatarOpacity: 1,
    disableTitleLink: true,
    details: [
        ptPartFields.self.dataCardRow({
            dataToRecord: (record: CustomerPricingModel) => record.partTierData,
        }),
        ptLaborFields.self.dataCardRow({
            dataToRecord: (record: CustomerPricingModel) => record.laborTierData,
        }),
        ptServiceFields.self.dataCardRow({
            dataToRecord: (record: CustomerPricingModel) => record.serviceTierData,
        }),
        ptTaxFields.self.dataCardRow({
            dataToRecord: (record: CustomerPricingModel) => record.taxTierData,
            isTaxExempt: (record: CustomerPricingModel) => record.isTaxExempt,
        }),
    ],

    actions: (record, { children, resource }) => [
        <CustomerPricingDrawerToggler
            key="edit"
            record={record}
            resource={resource.resource}
        >
            {({ onClick }) =>
                children({
                    title: 'Edit',
                    Icon: Icons.Edit,
                    key: 'edit',
                    onClick,
                })
            }
        </CustomerPricingDrawerToggler>,
    ],
}

const CustomerPricing: FC = () => {
    const record = useRecordContext<CustomerModel>()
    const isLoading = !record

    return (
        <PageContent>
            <ResourceContextProviderWithClearEffect
                value={{
                    name: customerPricingResourceName,
                    resource: `${customersResource.resource}/${record?.id}/pricing`,
                }}
            >
                <ListController
                    isLoading={isLoading}
                    sort={defaultSort}
                    sorts={sorts}
                    filters={filters}
                >
                    <ListHeader />
                    <ListUi
                        listFTUProps={{
                            linkText: '',
                        }}
                        columnsCfg={columnsConfig}
                        disableManageColumns
                        cardsCfg={cardsConfig}
                        disableExportButton
                    />
                </ListController>
            </ResourceContextProviderWithClearEffect>
        </PageContent>
    )
}

export default CustomerPricing
