import { Fragment } from 'react'

import { useRecordContext } from 'react-admin'

import {
    type DatagridColumnsProps,
    type CardListConfig,
    simpleTable,
    InPageTabs,
    FtuCreateText,
    type ListControllerProps,
    FilterValueInput,
    FilterDateRangeValue,
    ListController,
    ListUi,
} from 'components'
import { type SortPayload } from 'core'
import { formatDate, Navigate, useParams } from 'lib'
import {
    isUnitArchived,
    type UnitModel,
    type MeterModel,
    getMeterConfig,
    meterTypesForUnit,
    unitUrls,
    meterValue,
} from 'resources/units'
import { PageContent, Typography } from 'ui'

import {
    disableMeterSelection,
    unitMeterActions,
    unitMeterBulkActions,
    unitMeterExtendedActions,
} from './actions'
import { CreateMeterDrawerToggler, Header, MeterSource, NoTrackedMeters } from './components'
import UnitMetersResource from './components/UnitMetersResource'
import { translateMeterSource } from './constants'
import { type MeterUrlParams } from './utils'

const defaultSort: SortPayload<MeterModel> = {
    field: 'timestamp',
    order: 'DESC',
}

const sorts: ListControllerProps<MeterModel>['sorts'] = [
    { id: 'timestamp', label: 'Date' },
    { id: 'source', label: 'Source' },
    { id: 'value', label: 'Value' },
]

const filters: ListControllerProps<MeterModel>['filters'] = [
    {
        id: 'timestamp',
        label: 'Date',
        renderComponent: (props) => <FilterDateRangeValue {...props} />,
    },
    {
        id: 'source',
        label: 'Source',
        renderComponent: (props) => (
            <FilterValueInput
                {...props}
                makeItemLabel={({ id }) => translateMeterSource(id as MeterModel['source'])}
            />
        ),
    },
]

const UnitMeters = () => {
    const unit = useRecordContext<UnitModel>()
    const { type } = useParams<MeterUrlParams>()

    if (!unit) {
        return null
    }

    const meters = unit.lastMeters
    const settings = unit.settings

    const tabs = [
        ...meterTypesForUnit.filter((meterType) => settings[meterType]?.tracked),
        ...meterTypesForUnit.filter(
            (meterType) => !settings[meterType]?.tracked && meters[meterType]?.value != null,
        ),
    ].map((meterType) => ({
        label: getMeterConfig(meterType).name,
        value: meterType,
    }))

    if (!tabs.length) {
        return <NoTrackedMeters />
    }

    const meterConfig = getMeterConfig(type)
    const meterSettings = settings[type]

    if (!meterConfig) {
        return (
            <Navigate
                to={unitUrls.getMeters(unit.id, tabs[0].value)}
                replace
            />
        )
    }

    const isArchived = isUnitArchived(unit)

    const columnsConfig: DatagridColumnsProps<MeterModel> = {
        ...simpleTable,
        columns: [
            {
                field: 'value',
                headerName: 'Value',
                renderCell: ({ row }) => meterValue(row.value, row.type),
            },
            {
                field: 'timestamp',
                headerName: 'Date',
                valueFormatter: ({ value }) =>
                    formatDate(value, ({ shortenedDateTime }) => shortenedDateTime),
            },
            {
                field: 'source',
                headerName: 'Source',
                renderCell: ({ row }) => <MeterSource meter={row as MeterModel} />,
            },
        ],
        actions: isArchived ? null : ({ row }, args) => unitMeterActions(row as MeterModel, args),
        checkboxSelection: !isArchived,
    }

    const cardsConfig: CardListConfig<MeterModel> = {
        titleSource: (meter) => (
            <Typography variant="subtitle2">{meterValue(meter.value, meter.type)}</Typography>
        ),
        disableTitleLink: true,
        subTitleSource: (meter) => (
            <Typography
                variant="tooltip"
                display="flex"
            >
                <MeterSource meter={meter} />
            </Typography>
        ),
        defaultImage: null,
        details: [
            {
                source: 'timestamp',
                label: 'Date',
                render: (value) => formatDate(value, ({ shortenedDateTime }) => shortenedDateTime),
            },
        ],
        actions: isArchived ? undefined : unitMeterExtendedActions,
        actionsDisabled: (meter) => meter.source === 'WORK_ORDER',
    }

    const readOnly = isArchived || !meterSettings?.tracked

    return (
        <Fragment key={type}>
            {tabs.length > 1 ? (
                <InPageTabs
                    tabs={tabs}
                    basePath={unitUrls.getMeters(unit.id)}
                    tabMinWidth="130px"
                />
            ) : null}
            <PageContent>
                <UnitMetersResource>
                    <ListController
                        sort={defaultSort}
                        preferencesName="unit-meters"
                        filter={{ type }}
                        sorts={sorts}
                        filters={filters}
                    >
                        <Header />
                        <ListUi
                            disableSelectRecord={disableMeterSelection}
                            hideSearch
                            bulkActions={isArchived ? undefined : unitMeterBulkActions}
                            columnsCfg={columnsConfig}
                            cardsCfg={cardsConfig}
                            disableManageColumns
                            exportFileName={`${unit?.number}-meters_${meterConfig.name}`}
                            listFTUProps={{
                                secondaryTitle: readOnly ? '' : 'Would you like to add one?',
                                title: 'No Entries',
                                action: readOnly ? null : (
                                    <CreateMeterDrawerToggler type={type}>
                                        {(params) => (
                                            <FtuCreateText {...params}>
                                                Add Meter Entry
                                            </FtuCreateText>
                                        )}
                                    </CreateMeterDrawerToggler>
                                ),
                                linkText: null,
                            }}
                        />
                    </ListController>
                </UnitMetersResource>
            </PageContent>
        </Fragment>
    )
}

export default UnitMeters
