import { type FC } from 'react'

import { useShowContext } from 'react-admin'

import { type WithChildrenNode } from 'appTypes'
import { type IntervalsType } from 'resources/pm'
import { type UnitModel, isUnitArchived } from 'resources/units'
import { Badge, badgeClasses } from 'ui'

interface Props extends WithChildrenNode {
    unitPmRecord: IntervalsType
    disabled?: boolean
}

const PmBadge: FC<Props> = ({ children, unitPmRecord, disabled }) => {
    const { record: unit } = useShowContext<UnitModel>()
    const isArchived = isUnitArchived(unit)

    if (unitPmRecord.status === 'PLANNED' || isArchived || disabled) {
        return <>{children}</>
    }
    if (unitPmRecord.status === 'OVERDUE') {
        return (
            <Badge
                variant="dot"
                color="error"
            >
                {children}
            </Badge>
        )
    }
    return (
        <Badge
            variant="dot"
            sx={(theme) => ({
                [`& .${badgeClasses.badge}`]: {
                    backgroundColor: `${theme.palette.charts.orange} !important`,
                },
            })}
        >
            {children}
        </Badge>
    )
}

export default PmBadge
