import { type FC } from 'react'

import { inject, observer } from 'mobx-react'
import { useRecordContext } from 'react-admin'

import { InfoCard, InfoCardDetails, type InfoCardDetailsRow } from 'components'
import { type AuthStore } from 'core'
import { dateReadableFormat } from 'lib'
import { meterTypesConfig, meterTypesForUnit, meterValue } from 'resources/units'
import { isVendorWo, type WorkOrderModel } from 'resources/workOrders'

const DescriptionCreatedOnBy: FC = inject('auth')(
    observer(({ auth }: { auth: AuthStore }) => {
        const workOrder = useRecordContext<WorkOrderModel>()

        return (
            <InfoCard>
                <InfoCardDetails<WorkOrderModel>
                    details={[
                        auth.companySettings.hasInvoicing &&
                        workOrder.unitData.customer &&
                        !isVendorWo(workOrder)
                            ? {
                                  label: 'Customer PO Number',
                                  source: ({ salesOrderData }) => salesOrderData?.poNumber,
                              }
                            : null,
                        ...meterTypesForUnit.map(
                            (meterType): InfoCardDetailsRow<WorkOrderModel> => {
                                const value = workOrder.metersStart[meterType]

                                if (value == null) {
                                    return null
                                }

                                const meterConfig = meterTypesConfig[meterType]

                                return {
                                    label: `Start ${meterConfig.name}`,
                                    source: () => meterValue(value, meterType),
                                }
                            },
                        ),
                        {
                            label: 'Created on',
                            source: ({ created }) => dateReadableFormat(created),
                        },
                        {
                            label: 'Created By',
                            source: ({ createdBy }) => createdBy?.name || createdBy?.email,
                        },
                    ]}
                />
            </InfoCard>
        )
    }),
)

export default DescriptionCreatedOnBy
